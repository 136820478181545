<template>
<div class="m-2">
  <b-tabs>
    <b-tab title="Selection">
      <b-table class="w-25" striped hover :fields="fields" :items="items"></b-table>
    </b-tab>
    <b-tab title="Generated">
      <b-table class="w-25" striped hover :fields="fields" :items="genItems"></b-table>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("mime");

export default {
  data() {
    return {
      items: [],
      genItems: [],

      fields: [
        {
          key: "type",
        },
        {
          key: "recording",
          sortable: true,
        },
        {
          key: "playback",
          sortable: true,
        },
        {
          key: "mediaSource",
          sortable: true,
        },
      ],

      types: [
        "video",
        "audio",
        "video/webm",
        "video/ogg",
        "video/mpeg",
        "video/mp4",
        "video/mpeg4",
        "audio/wav",
        "audio/mp4;codec=aac",
        "audio/mp4;codec=flac",
        "audio/webm",
        "audio/webm;codec=aac",
        "audio/webm;codecs=opus",
        "audio/webm;codec=flac",
        "audio/webm;codec=mp3",
        "audio/aac",
        "audio/mp4a",
        "audio/flac",
        "video/webm;codecs=vp8",
        "video/webm;codecs=vp9",
        "video/webm;codecs=vp9,opus",
        "video/webm;codecs=daala",
        "video/webm;codecs=h.263",
        "video/webm;codecs=h264",
        'video/webm;codecs=h264,opus',
        "video/webm;codecs=h264,mp4a",
        "video/webm;codecs=h264,aac",
        "video/webm;codecs=h264,mp3",
        "video/webm;codecs=avc1",
        "video/webm;codecs=h265",
        "video/webm;codecs=avc1.4d002a",
        "video/webm;codecs=avc1.4D401E,mp4a.40.2",
        'video/webm;codecs="av01.2.19H.12.0.000.09.16.09.1,flac"',
        'video/mp4; codecs="av01.2.19H.12.0.000.09.16.09.1,flac"',
        'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
        'video/mp4; codecs="h.264, aac"',
        "video/x-matroska;codecs=avc1",
        'video/x-matroska;codecs="h264,aac"',
        'video/mp4; codecs="avc1.64001F,mp4a.40.2"',
      ],
    }
  },
  mounted() {
    this.items = this.typeSupportTest(this.types);
    this.genItems = this.typeSupportTest(this.generateTypes());
  },
  methods: {
    generateTypes() {
      const VIDEO_TYPES = [
        "webm", 
        "ogg",
        "mp4",
        "x-matroska"
      ];
      const VIDEO_CODECS = [
        "vp9",
        "vp9.0",
        "vp8",
        "vp8.0",
        "avc1",
        "av1",
        "h265",
        "h.265",
        "h264",
        "h.264",
        "opus",
      ];
      const supportedTypes = [];
      VIDEO_TYPES.forEach((videoType) => {
        const type = `video/${videoType}`;
        VIDEO_CODECS.forEach((codec) => {
            const variations = [
            `${type};codecs=${codec}`,
            `${type};codecs=${codec.toUpperCase()}`,
            `${type}`
          ]
          variations.forEach(variation => {
            supportedTypes.push(variation);
          })
        });
      });
      return supportedTypes;
    },
    isPlaybackTypeSupported(type) {
      if (!this.audio)
        this.audio = document.createElement('audio');
      if (!this.video)
        this.video = document.createElement('video');
      if (type.startsWith('audio'))
        return this.audio.canPlayType(type);
      else 
        return this.video.canPlayType(type);
    },
    typeSupportTest(types) {
      log.log("typeSupportTest", types);
      let MediaSourceIsTypeSupported = (typeof MediaSource !== "undefined" && MediaSource?.isTypeSupported) || (() => { return false; } );
      let res = [];
      for (let i in types) {
        log.log("type:", types[i]);
        let recording = MediaRecorder.isTypeSupported(types[i]);
        let playback = this.isPlaybackTypeSupported(types[i]);
        let mediasource = MediaSourceIsTypeSupported(types[i]);
        res.push({ type: types[i], recording, playback: playback || "no", mediaSource: mediasource || "no",
          _cellVariants: { 
            recording: recording ? "success" : "danger",
            playback: playback != "" ? "success" : "danger",
            mediaSource: mediasource != "" ? "success" : "danger",
          } 
        });
      }
      return res;
    }
  }
}
</script>

<style>

</style>
